<template>
  <el-container>
    <el-main>
      <AlipayLogo></AlipayLogo>
      <el-row>
        <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
        <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
        <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
      </el-row>
      <el-row>
        <OrderInfo :money="money" :order-no="orderNo"></OrderInfo>
      </el-row>
      <el-row>
        <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
      </el-row>
      <el-row>
        <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
      </el-row>
    </el-main>
  </el-container>
</template>
<script>
import Request from "@/request";
import AlipayLogo from "@/components/AlipayLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import AlipayButton from "@/components/AlipayButton.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import OrderInfo from "@/components/OrderInfo.vue";

export default {
  name: 'AlipayUid',
  components: {
    OrderInfo,
    PayTips,
    AlipayLogo,
    CountDown,
    Qrcode,
    AlipayButton
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      authCode: this.$route.query.auth_code === undefined ? '' : this.$route.query.auth_code,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: false,
      dialogShowClose: false,
    }
  },
  methods: {
    getData() {
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        //this.qrShow = false
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        //跳到支付宝授权
        this.h5Url = this.$utils.getHb(window.location.href)
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //请求获取支付参数，并启动支付宝
    getPayData() {
      Request.get("/alipayAuth?orderNo=" + this.orderNo + "&authCode=" + this.authCode).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        //this.payButtonShow = true
        this.h5Url = this.$utils.getHbPayUrl(res.data)
        window.location.href = this.h5Url
      });
    },
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
    //如果获取到支付宝auth_code
    if (this.authCode !== '') {
      this.getPayData()
    }
  },
}
</script>
