import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  Button,
  Form,
  Container,
  Main,
  Row,
  FormItem,
  Input,
  Select,
  Link,
  Option,
  Message,
  Result,
  Tag,
  Dialog,
  Loading,
  Divider,
  Col
} from 'element-ui';


import axios from 'axios'
Vue.prototype.$axios = axios

import './assets/css/index.css'

import utils from './utils/index'
Vue.prototype.$utils = utils

Vue.config.productionTip = false

Vue.use(Button)
Vue.use(Form)
Vue.use(Container)
Vue.use(Main)
Vue.use(Row)
Vue.use(Col)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Link)
Vue.use(Option)
Vue.use(Result)
Vue.use(Tag)
Vue.use(Dialog)
Vue.use(Divider)

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;

Vue.prototype.$message = Message;

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
