import Vue from 'vue'
import VueRouter from 'vue-router'
import TBDAIFU from '../views/TBDAIFU.vue'
import Test from '../views/Test.vue'
import AlipayUid from "@/views/AlipayUid.vue";
import AlipayAA from "@/views/AlipayAA.vue";
import WxScan from "@/views/WxScan.vue";
import XianyuZF from "@/views/XianyuZF.vue";
import AlipayYinlian from "@/views/AlipayYinlian.vue";
import AlipayFxc from "@/views/AlipayFxc.vue";
import ManualAlipayUid from "@/views/ManualAlipayUid.vue";
import TaobaoZF from "@/views/TaobaoZF.vue";
import AlipayXhb from "@/views/AlipayXhb.vue";
import AlipayLs from "@/views/AlipayLs.vue";
import TaobaoGame from "@/views/TaobaoGame.vue";
import Tk from "@/views/Tk.vue";
import TkCreateOrder from "@/views/TkCreateOrder.vue";
import AlipayDmf from "@/views/AlipayDmf.vue";
import AlipayDmfFenzhang from "@/views/AlipayDmfFenzhang.vue";
import Douyin from "@/views/Douyin.vue";
import AlipayWap from "@/views/AlipayWap.vue";
import AlipayWapFenzhang from "@/views/AlipayWapFenzhang.vue";
import Wanmei from "@/views/Wanmei.vue";
import AlipayorderPay from "@/views/AlipayorderPay.vue";
import Tl from "@/views/Tl.vue";
import TlCreateOrder from "@/views/TlCreateOrder.vue";
import DouyinCreateOrder from "@/views/DouyinCreateOrder.vue";
import Shj from "@/views/Shj.vue";
import DmfLittleCreateOrder from "@/views/DmfLittleCreateOrder.vue";
import OrderLittleOrder from "@/views/OrderLittleOrder.vue";
import AlipayYinlianBak from "@/views/AlipayYinlianBak.vue";
import Lpk from "@/views/Lpk.vue";
import LpkSYT from "@/views/LpkSYT.vue";
import AlipayRedpacket from "@/views/AlipayRedpacket.vue";
import DouyinZhuanzhang from "@/views/DouyinZhuanzhang.vue";
import AlipayPage from "@/views/AlipayPage.vue";
import AlipayDf from "@/views/AlipayDf.vue";
import Jym from "@/views/Jym.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta:{
      title:"测试下单"
    }
  },
  {
    path: '/alipay_xianyu',
    name: 'tbdaifu',
    component: TBDAIFU,
    meta:{
      title:"TB代付H5"
    }
  },
  {
    path: '/alipay_uid',
    name: 'alipayUid',
    component: AlipayUid,
    meta:{
      title:"支付宝uid"
    }
  },
  {
    path: '/alipay_aa',
    name: 'alipayAA',
    component: AlipayAA,
    meta:{
      title:"支付宝AA"
    }
  },
  {
    path: '/wx_scan',
    name: 'wxScan',
    component: WxScan,
    meta:{
      title:"微信扫码"
    }
  },
  {
    path: '/xianyu_zf',
    name: 'xianyuZf',
    component: XianyuZF,
    meta:{
      title:"闲鱼直付"
    }
  },
  {
    path: '/alipay_yinlian',
    name: 'alipayYinlian',
    component: AlipayYinlianBak,
    meta:{
      title:"支付宝银联"
    }
  },
  {
    path: '/alipay_fxc',
    name: 'alipayFxc',
    component: AlipayFxc,
    meta:{
      title:"支付宝放心充"
    }
  },
  {
    path: '/manual_alipay_uid',
    name: 'manual_alipay_uid',
    component: ManualAlipayUid,
    meta:{
      title:"支付宝uid手动回调"
    }
  },
  {
    path: '/taobao_zf',
    name: 'taobao_zf',
    component: TaobaoZF,
    meta:{
      title:"淘宝直付"
    }
  },
  {
    path: '/alipay_xhb',
    name: 'alipayXhb',
    component: AlipayXhb,
    meta:{
      title:"支付宝小荷包"
    }
  },
  {
    path: '/alipay_ls',
    name: 'alipayLs',
    component: AlipayLs,
    meta:{
      title:"支付宝LS"
    }
  },
  {
    path: '/taobao_game',
    name: 'taobao_game',
    component: TaobaoGame,
    meta:{
      title:"淘宝直付-游戏"
    }
  },
  {
    path: '/tk',
    name: 'tk',
    component: Tk,
    meta:{
      title:"tk"
    }
  },
  {
    path: '/tkCreateOrder',
    name: 'tkCreateOrder',
    component: TkCreateOrder,
    meta:{
      title:"收银台"
    }
  },
  {
    path: '/alipay_dmf',
    name: 'alipay_dmf',
    component: AlipayDmf,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipay_dmf_fenzhang',
    name: 'alipay_dmf_fenzhang',
    component: AlipayDmfFenzhang,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/douyin',
    name: 'douyin',
    component: Douyin,
    meta:{
      title:"抖音微播"
    }
  },
  {
    path: '/alipay_wap',
    name: 'alipay_wap',
    component: AlipayWap,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipay_wap_fenzhang',
    name: 'alipay_wap_fenzhang',
    component: AlipayWapFenzhang,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/wanmei',
    name: 'wanmei',
    component: Wanmei,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipay_order_pay',
    name: 'alipay_order_pay',
    component: AlipayorderPay,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/tl',
    name: 'tl',
    component: Tl,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/tlCreateOrder',
    name: 'tlCreateOrder',
    component: TlCreateOrder,
    meta:{
      title:"收银台"
    }
  },
  {
    path: '/dmfLittleCreateOrder',
    name: 'dmfLittleCreateOrder',
    component: DmfLittleCreateOrder,
    meta:{
      title:"收银台"
    }
  },
  {
    path: '/orderLittleOrder',
    name: 'orderLittleOrder',
    component: OrderLittleOrder,
    meta:{
      title:"收银台"
    }
  },
  {
    path: '/douyinCreateOrder',
    name: 'douyinCreateOrder',
    component: DouyinCreateOrder,
    meta:{
      title:"收银台"
    }
  },
  {
    path: '/shj',
    name: 'shj',
    component: Shj,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/lpk',
    name: 'lpk',
    component: Lpk,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/lpkSyt',
    name: 'lpkSyt',
    component: LpkSYT,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipay_redpacket',
    name: 'alipayRedpacket',
    component: AlipayRedpacket,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/douyin_zhuanzhang',
    name: 'douyin_zhuanzhang',
    component: DouyinZhuanzhang,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipayPage',
    name: 'alipayPage',
    component: AlipayPage,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/alipay_dfck',
    name: 'alipayDf',
    component: AlipayDf,
    meta:{
      title:"支付宝付款"
    }
  },
  {
    path: '/jym',
    name: 'jym',
    component: Jym,
    meta:{
      title:"支付宝付款"
    }
  },
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
