<template>
  <el-row>
    <el-col :span="12" class="elCol" v-for="(item, index) in ios" :key="index" v-if="platform === 'IOS'">
      <a :href="item.scheme">
        <img :src="require(`../assets/img/${item.img}`)" alt="" class="bankImg">
      </a>
    </el-col>
    <el-col :span="12" class="elCol" v-for="(item, index) in android" :key="index" v-if="platform === 'Android'">
      <a :href="item.scheme">
        <img :src="require(`../assets/img/${item.img}`)" alt="" class="bankImg">
      </a>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      platform:this.checkPlatform(),
      ios: [
        {scheme: 'com.icbc.iphoneclient://', img: 'gongshang.png'},
        {scheme: 'bankabc://', img: 'nongye.png'},
        {scheme: 'bocmbciphone://', img: 'zhongguoyinhang.png'},
        {scheme: 'bocmbciphone://', img: 'jianshe.png'},
        {scheme: 'bocom://', img: 'jiaotong.png'},
        {scheme: 'com.cmbc.cn.iphone://', img: 'minsheng.png'},
        {scheme: 'com.cebbank.ebank://', img: 'guangda.png'},
        {scheme: 'psbcMobileBank://', img: 'youzheng.png'},
        {scheme: 'citic/citicbank://', img: 'zhongxin.png'},
        {scheme: 'paebqw/paesuperbank://', img: 'pingan.png'},
        {scheme: 'cibmb://', img: 'xingye.png'},
        {scheme: 'spdbbank://', img: 'pufa.png'},
        {scheme: 'cgb/cgbMobileHuidu://', img: 'guangfa.png'},
        {scheme: 'hxbmb://', img: 'huaxia.png'},
        {scheme: 'BankOfShangHai://', img: 'shanghai.png'},
        {scheme: 'nbbank://', img: 'ningbo.png'},
        {scheme: 'jsbsjyh://', img: 'jiangsu.png'},
        {scheme: 'nicbmbank://', img: 'nanjing.png'},
        {scheme: 'gzcb://', img: 'guangzhou.png'},
      ],
      android: [
        {scheme: 'com.icbc.androidclient://', img: 'gongshang.png'},
        {scheme: 'bankabc://', img: 'nongye.png'},
        {scheme: 'bocmbciphone://h5://', img: 'zhongguoyinhang.png'},
        {scheme: 'ccbapp://main.ccb.com://', img: 'jianshe.png'},
        {scheme: 'bocom://', img: 'jiaotong.png'},
        {scheme: 'cmbc://qd.mbank://', img: 'minsheng.png'},
        //{scheme:'com.cebbank.ebank://',img:'guangda.png'},
        {scheme: 'psbc://mainpage://', img: 'youzheng.png'},
        {scheme: 'citicbankpay://unionPay:8899://', img: 'zhongxin.png'},
        {scheme: 'com.pingan.paces.ccms://anydoor://', img: 'pingan.png'},
        {scheme: 'cibmb://jumpurl://', img: 'xingye.png'},
        {scheme: 'spdbbank://wap.spdb.com.cn://', img: 'pufa.png'},
        {scheme: 'cgb://lua/openxml://', img: 'guangfa.png'},
        {scheme: 'hxbmb://mb5://', img: 'huaxia.png'},
        {scheme: 'mobile://BankOfShangHai.com://', img: 'shanghai.png'},
        {scheme: 'nbbank://com.nbbank://', img: 'ningbo.png'},//
        {scheme: 'jsbsjyh://mbank.jsbchina.sjyh://', img: 'jiangsu.png'},
        {scheme: 'nicbmbank://page/sdm://', img: 'nanjing.png'},
        //{scheme:'gzcb://',img:'guangzhou.png'},
      ]
    }
  },
  methods: {
    //获取当前系统类型
    checkPlatform() {
      // 获取 User-Agent 字符串
      const userAgent = navigator.userAgent;
      const isiOS = /iPhone|iPad|iPod/i.test(userAgent);
      const isAndroid = /Android/i.test(userAgent);
      if (isiOS) {
        return 'IOS'
      } else if (isAndroid) {
        return 'Android'
      } else {
        return ''
      }

    }
  }
}
</script>
<style>
.bankImg {
  width: 150px;
  height: 50px;
  display: block;
}

.elCol {
  margin-bottom: 5px;
}
</style>