<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <el-form ref="form" :rules="rules" :model="form" label-width="100px">
            <el-form-item label="请求时间戳" prop="mchReqTime">
              <el-input v-model="form.mchReqTime"></el-input>
            </el-form-item>
            <el-form-item label="商户订单号" prop="mchOrderNo">
              <el-input v-model="form.mchOrderNo"></el-input>
            </el-form-item>
            <el-form-item label="回调地址" prop="mchNotifyUrl">
              <el-input v-model="form.mchNotifyUrl"></el-input>
            </el-form-item>
            <el-form-item label="商户号" prop="mchId">
              <el-input v-model="form.mchId"></el-input>
            </el-form-item>
            <el-form-item label="商户密钥" prop="mchKey">
              <el-input v-model="form.mchKey" show-password></el-input>
            </el-form-item>
            <el-form-item label="支付类型" prop="mchPayType">
              <el-select  v-model="form.mchPayType" placeholder="请选择" style="width: 100%;">
                <el-option
                    v-for="item in this.mchPayTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单金额" prop="mchMoney">
              <el-input v-model="form.mchMoney" type="number"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">立即创建</el-button>
              <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
          </el-form>
          <el-row>
            <span>下单结果：</span><el-link :href=returnUrl target="_blank">{{returnUrl}}</el-link>
          </el-row>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Dayjs from 'dayjs'
import CryptoJS from 'crypto-js'
import Request from "@/request";
export default {
  name: 'Test',
  data() {
    return {
      returnUrl:'',
      formData:{},
      form: {
        mchId: 'zvyegj1mftgwfest',
        mchKey: 'n601dya8lv8oja9hqjul5jurn43nvqtl',
        mchMoney: 1,
        mchOrderNo: '',
        mchNotifyUrl: '',
        mchPayType: '',
        mchReqTime: ''
      },
      mchPayTypeOptions:[

      ],
      rules: {
        mchOrderNo: [
          {required: true, message: '请输入商户订单号', trigger: 'blur'}
        ],
        mchNotifyUrl: [
          {required: true, message: '请输入回调地址', trigger: 'blur'}
        ],
        mchId: [
          {required: true, message: '请输入商户号', trigger: 'blur'}
        ],
        mchKey: [
          {required: true, message: '请输入商户密钥', trigger: 'blur'}
        ],
        mchPayType: [
          {required: true, message: '请输入支付类型', trigger: 'blur'}
        ],
        mchMoney: [
          {required: true, message: '请输入订单金额', trigger: 'blur'}
        ],
        mchReqTime: [
          {required: true, message: '请输入请求时间戳', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    //生成13位时间戳
    createTime(){
      return Math.floor(new Date().getTime())
    },
    //生成订单号
    createOrderNo(){
      return Dayjs().valueOf() + Math.floor(Math.random() * (9999 - 1000)) + 1000
    },
    //初始化参数
    createInfo() {
      this.form.mchOrderNo = this.createOrderNo()
      this.form.mchNotifyUrl = process.env.VUE_APP_BASE_API + "/test/notify"
      this.form.mchReqTime = this.createTime()
    },
    //提交表单
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //组装数据
          this.formData.mchId = this.form.mchId
          this.formData.mchMoney = this.form.mchMoney
          this.formData.mchOrderNo = this.form.mchOrderNo
          this.formData.mchNotifyUrl = this.form.mchNotifyUrl
          this.formData.mchPayType = this.form.mchPayType
          this.formData.mchReqTime = this.form.mchReqTime
          this.formData.mchSign = this.createSign(this.form,this.form.mchKey)
          //生成新的订单号
          this.createInfo()
          Request.post("/api/order",this.formData).then(res=>{
            if(res.code === 0){
              this.$message({
                message: '下单成功！支付链接在底部查看！',
                type: 'success'
              });
              this.returnUrl = res.data.payUrl
            }else{
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          }).catch(()=>{})
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(form) {
      this.$refs[form].resetFields();
    },
    //生成签名字符串
    createSign(data,key) {
      //组装数据
      let newData = {};
      newData.mchId = data.mchId
      newData.mchMoney = data.mchMoney
      newData.mchOrderNo = data.mchOrderNo
      newData.mchNotifyUrl = data.mchNotifyUrl
      newData.mchPayType = data.mchPayType
      newData.mchReqTime = data.mchReqTime
      let arr = [];
      let num = 0;
      for (let i in newData) {
        arr[num] = i;
        num++;
      }
      let sortArr = arr.sort();
      let str = '';             //自定义排序字符串
      for (let i in sortArr) {
        str += sortArr[i] + '=' + newData[sortArr[i]] + '&';
      }
      //去除两侧字符串
      let char = '&'
      str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
      //拼接key字符串
      let signStr = str + "&key="+key
      //console.log("待签名字符串:"+signStr);
      //md5加密
      //console.log("sign:"+CryptoJS.MD5(signStr).toString());
      return CryptoJS.MD5(signStr).toString()
    },
    //获取支付类型参数
    getPayType(){
      Request.get("/api/payType").then(res=>{
        this.mchPayTypeOptions  = res.map(item => {
          return {
            value: item.no,
            label: item.title
          };
        });
      })
    },
  },
  created() {
    this.createInfo()
    this.getPayType()
  }
}
</script>
