<template>
  <div>
  </div>
</template>
<script>
export default {
  name: 'alipayPage',
  data() {
    return {
      qrcode:this.$route.query.qrcode === undefined ? '' :this.$route.query.qrcode
    }
  },
  methods: {

  },
  mounted() {
    console.log(this.qrcode)
    if(this.qrcode !== ''){
      window.location.href = this.$utils.getAlipayQrcode(this.qrcode)
    }

  },
}
</script>
