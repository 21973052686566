<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元</div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row>
          <el-button type="primary" v-show="payButtonShow" @click="goPay()">打开淘宝APP付款</el-button>
        </el-row>
        <el-row>
          <p class="payTips">付款步骤，请仔细阅读:</p>
          <p class="payTips">1.<span class="text-red">截屏保存当前二维码</span></p>
          <p class="payTips">2.手动打开 <span class="text-red">淘宝APP扫一扫</span>，选择刚才保存的二维码</p>
          <p class="payTips">3.购买商品完成后，一定要确认收货</p>
          <p class="payTips">5.重要！！！支付成功以后一定要<span class="text-red">确定收货</span>秒上分</p>
        </el-row>
        <el-dialog
            width="80%"
            :visible.sync="dialogFlag"
            center
            top="1vh">
          <img src="../assets/img/taobaoZf.jpg" alt="" width="100%">
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogFlag = false">我已知晓</el-button>
          </span>
        </el-dialog>

      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import CountDown from "@/components/CountDown.vue"
import startCheckOrderTimer from "@/service/checkOrder";
import Qrcode from "@/components/Qrcode.vue";

export default {
  name: 'TaobaoZF',
  components: {
    Qrcode,
    CountDown,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      h5Url: '',
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      dialogFlag: true,
      showCloseFlag: true,
    }
  },
  methods: {
    getData() {
      //订单号
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        //this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.h5Url = this.$utils.getTaobaoZfv2(data.goodsId)
        this.money = data.money
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      this.dialogFlag = false
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //弹窗
    handleClose() {
      this.dialogFlag = false
    },
    //跳转支付
    goPay() {
      window.location.href = this.h5Url
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>
.payTips {
  font-size: 15px;
  font-weight: bold;
  text-align: left;

}
</style>
