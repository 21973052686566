<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元
            <el-button class="copyMoney" type="primary" size="mini" :data-clipboard-text="money" @click="handleCopy">
              复制金额
            </el-button>
          </div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row>
          <AlipayButton :h5Url="h5Url" v-show="payButtonShow"></AlipayButton>
        </el-row>
        <el-row style="text-align: left;color: red;">
          <div>1. 点击复制金额按钮</div>
          <div>2. 点击启动支付宝付款，粘贴付款金额</div>
          <div><span style="font-weight: bold;color: black;">⚠️ 注意:</span><br>
            请付款时一定要输入订单金额，精确到小数，否则付款失败！<br>

            请勿超时支付，否则付款失败！
          </div>
        </el-row>
        <el-row>
<!--          <Bank></Bank>-->
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import WxLogo from "@/components/WxLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import Bank from "@/components/Bank.vue";
import ClipboardJS from "clipboard";
import AlipayButton from "@/components/AlipayButton.vue";

export default {
  name: 'wxScan',
  components: {
    AlipayButton,
    WxLogo,
    PayTips,
    CountDown,
    Qrcode,
    Bank,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      h5Url: '',
    }
  },
  methods: {
    getData() {
      //请求订单信息
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        this.qrShow = true
        this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        this.h5Url = this.$utils.getAlipayLs(data.pathContent)
        this.money = data.payMoney
      }).catch(() => {
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //处理复制金额
    handleCopy() {
      const clipboard = new ClipboardJS('.copyMoney');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制金额:" + e.text )
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制金额失败，请手动填写金额")
        clipboard.destroy(); // 取消事件监听
      });
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>

</style>
