<template>
  <div>
    <el-container>
      <el-main>
        <el-row>
          <Qrcode :content="h5Url" v-show="qrShow"></Qrcode>
          <el-result icon="error" title="订单已超时，请勿超时付款" v-show="timeOut"></el-result>
          <el-result icon="success" title="您已成功付款" v-show="paySuccessFlag"></el-result>
        </el-row>
        <el-row>
          <img :src="path" width="100%">
        </el-row>
        <el-row>
          <div class="orderInfo">订单金额：<span class="moneyTitle">{{ money }}</span>元
          </div>
          <div class="orderInfo">
            <CountDown :expirationDateTime="closeTime" :onCountdownEnd="handleCountdownEnd" v-if="countDownFlag"/>
          </div>
          <div class="orderInfo">订单号：
            <el-tag size="small">{{ orderNo }}</el-tag>
          </div>
        </el-row>
        <el-row style="text-align: left;color: red;">
          <div>1. 截图保存当前二维码</div>
          <div>2. 打开微信扫一扫，选择保存的二维码</div>
          <div>3. 选择当前订单对应的金额，点击赞赏付款</div>
        </el-row>
        <el-row>
<!--          <Bank></Bank>-->
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Request from "@/request";
import WxLogo from "@/components/WxLogo.vue";
import PayTips from "@/components/PayTips.vue";
import CountDown from "@/components/CountDown.vue"
import Qrcode from "@/components/Qrcode.vue";
import startCheckOrderTimer from "@/service/checkOrder";
import Bank from "@/components/Bank.vue";
import ClipboardJS from "clipboard";

export default {
  name: 'wxScan',
  components: {
    WxLogo,
    PayTips,
    CountDown,
    Qrcode,
    Bank,
  },
  data() {
    return {
      qrShow: false,
      payButtonShow: false,
      orderNo: this.$route.query.no,
      money: 0,
      closeTime: '',
      timeOut: false,
      countDownFlag: true,
      paySuccessFlag: false,
      h5Url: '',
      path:'',
    }
  },
  methods: {
    getData() {
      //请求订单信息
      Request.get("/show/qrc?no=" + this.orderNo).then(res => {
        if (res.code !== 0) {
          this.$message({message: res.msg, type: 'error',})
          return
        }
        //this.qrShow = true
        //this.payButtonShow = true
        const data = res.data
        this.closeTime = this.$utils.getRealCloseTime(data.closeTime)
        //this.h5Url = data.path
        this.path = data.path
        this.money = data.money
      }).catch(() => {
      });
    },
    //处理倒计时
    handleCountdownEnd() {
      this.qrShow = false
      this.payButtonShow = false
      this.timeOut = true
      //销毁检查订单任务
      this.stopCheckOrderTimer()
    },
    //处理检查订单状态
    handleCheckOrder(data) {
      if (data.code !== 0) {
        return
      }
      if (data.data.state === "TIMEOUT") {
        //销毁检查订单任务
        this.stopCheckOrderTimer()
      }
      if (data.data.state === "OOK") {
        //显示成功付款提示
        this.paySuccessFlag = true
        //销毁倒计时组件
        this.countDownFlag = false
        //销毁检查订单任务
        this.stopCheckOrderTimer()
        //隐藏付款码
        this.qrShow = false
        this.payButtonShow = false
      }
    },
    //处理复制金额
    handleCopy() {
      const clipboard = new ClipboardJS('.copyMoney');
      clipboard.on('success', (e) => {
        this.$message.success("成功复制金额:" + e.text + "，请截图前往银行APP付款！")
        e.clearSelection();
        clipboard.destroy(); // 取消事件监听
      });
      clipboard.on('error', () => {
        this.$message.error("复制金额失败，请手动填写金额")
        clipboard.destroy(); // 取消事件监听
      });
    }
  },
  mounted() {
    this.getData()
    this.stopCheckOrderTimer = startCheckOrderTimer(this.orderNo, this.handleCheckOrder)
  },
}
</script>
<style>

</style>
